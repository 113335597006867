<template>
  <b-card
    v-if="dataList.length > 0"
    title="Yetkili Kişiler"
  >
    <b-list-group>
      <b-list-group-item
        v-for="person in dataList"
        :key="person.id"
      >
        <div
          class="text-primary font-weight-bold"
          :class="person.deleted? 'text-muted' : ''"
        >
          {{ person.related_person_types }}
        </div>
        <div
          class="font-medium-2 font-weight-bold"
          :class="person.deleted? 'text-muted' : ''"
        >
          {{ person.name }}
        </div>
        <div
          class="mt-1"
          :class="person.deleted? 'text-muted' : ''"
        >
          <FeatherIcon icon="PhoneIcon" /> +{{ person.phone_region_code }} {{ person.phone }}
        </div>
        <div
          class="mt-1"
          :class="person.deleted? 'text-muted' : ''"
        >
          <FeatherIcon icon="MailIcon" /> {{ person.email }}
        </div>
        <div
          v-if="person.deleted"
          class="mt-1 text-danger font-small-2"
        >
          {{ moment(person.deleted).format('LL') }} tarihinde ilgili kişi silindi.
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'RelatedPersons',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    dataList() {
      return this.$store.getters['customerRelatedPersons/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('customerRelatedPersons/getDataList', {
        where: {
          'customer_related_persons.id_customers': this.$route.params.id,
        },
        deleted: true,
      })
    },
  },
}
</script>
