<template>
  <b-alert
    variant="warning"
    show
  >
    <div class="alert-body text-center">
      <FeatherIcon icon="InfoIcon" />
      <p>Kayıt bulunamadı.</p>
    </div>
  </b-alert>
</template>
<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'EmptyAlert',
  components: {
    BAlert,
  },
}
</script>
