<template>
  <b-card
    v-if="dataList.length > 0"
    title="Sosyal Medya"
  >
    <b-list-group>
      <b-list-group-item
        v-for="item in dataList"
        :key="item.id"
        class="socialMedia"
      >
        <b-row class="d-flex align-items-center">
          <b-col
            cols="auto"
          >
            <div class="bg-primary rounded-circle item d-flex align-items-center">
              <b-img
                :src="imageUrl + '/media/socialmedia/' + item.icon"
              />
            </div>
          </b-col>
          <b-col>
            <b-link
              :href="item.url"
              target="_blank"
            >
              <div>
                {{ item.title }}
              </div>
              <div class="text-muted font-small-2">
                {{ item.url }}
              </div>
            </b-link>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol, BImg, BLink,
} from 'bootstrap-vue'

export default {
  name: 'SocialMedia',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BImg,
    BLink,
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customerSocialMedia/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('customerSocialMedia/getDataList', {
        where: {
          'customer_socialmedia.id_customers': this.$route.params.id,
        },
      })
    },
  },
}
</script>
<style scoped>
.socialMedia .item{
  width: 30px;
  height: 30px;
}
.socialMedia .item img{
  width: 20px;
  height: 20px;
  margin: 0 auto;
}
</style>
